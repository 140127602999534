<template>
  <div class="inline-block">
    <LoadingBtn :uiid="uiid" size="small" :type="btnType" @click="showDialog">{{ title }}</LoadingBtn>
    <BaseDialog :append-to-body="true" :dialogVisible.sync="dialogVisible" :title="baseTitle" width="592px">
      <p>{{ info }}:</p>
      <setPassword v-if="showPassword" ref="password" />
      <el-input
        v-else
        clearable
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 10 }"
        placeholder="多行输入"
        v-model="textarea"
      >
      </el-input>

      <template #footer>
        <LoadingBtn size="small" @click="cancel">取消</LoadingBtn>
        <LoadingBtn size="small" type="primary" @click="submit">确定</LoadingBtn>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { commonEheaderMixin } from '@/mixins'
import setPassword from './setPassword'
import {
  HANDLE_STATUS_PENDING,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
  HANDLE_STATUS_SUCCESS_BY_FINANCE,
  HANDLE_STATUS_REFUSE_BY_FINANCE_BACK,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_REFUSE_BY_FINANCE
} from '@/utils/constant'
import { accSub } from '@/utils'
import { passwordSetted } from '@/api/balanceApi'
import { mapGetters } from 'vuex'
import { PAYMENT_TYPE_SYS_DIC } from '@/utils/constant'
import { findByvalue } from '@/components/avue/utils/util'

export default {
  mixins: [commonEheaderMixin],
  components: { setPassword },
  props: {
    initValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sup_this: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: ''
    },
    openValidate: {
      default: () => true
    },

    uiid: String
  },
  data() {
    return {
      HANDLE_STATUS_PENDING,
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER,
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
      HANDLE_STATUS_SUCCESS_BY_FINANCE,
      HANDLE_STATUS_REFUSE_BY_FINANCE_BACK,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_FINANCE,
      baseTitle: '审核',
      info: '审核意见',
      textarea: '',
      showPassword: false,
      type: 'primary'
    }
  },
  computed: {
    ...mapGetters(['is_super', 'name'])
  },
  watch: {
    title: {
      handler(n) {
        this.type = 'primary'
        if (n == '拒绝') {
          this.type = ''
        }
      },
      mmediate: true
    },
    initValue: {
      handler(n) {
        this.textarea = ''
        let handleStatus = n?.handleStatus
        if ([HANDLE_STATUS_SUCCESS_BY_CUSTOMER, HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER].includes(handleStatus)) {
          this.info = '审核意见'
        }
        if (
          [HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK, HANDLE_STATUS_REFUSE_BY_FINANCE_BACK].includes(handleStatus)
        ) {
          this.info = '退回理由'
        }
        if ([HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER, HANDLE_STATUS_REFUSE_BY_FINANCE].includes(handleStatus)) {
          this.info = '拒绝理由'
        }
        if (handleStatus == HANDLE_STATUS_SUCCESS_BY_FINANCE) {
          this.info = '交易密码'
          this.showPassword = true
        } else {
          this.showPassword = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async showDialog() {
      this.validateData()
    },
    cancel() {
      this.dialogVisible = false
    },
    async validateData() {
      // 金额判断
      let curType = this.initValue.curType
      let type = this.initValue.type
      let amount = this.initValue.amount
      let detail = this.initValue.detail
      let paymentType = this.initValue.paymentType
      let label = findByvalue(PAYMENT_TYPE_SYS_DIC, paymentType) || '余额'

      if (curType == 'shipped') {
        let compensateType = this.initValue.compensateType
        if (compensateType == 1) {
          let maxAmount = accSub(detail.maxAmount, detail.realTemplateFreight)
          if (amount > maxAmount && this.title == '通过') {
            this.warnInfo(amount, maxAmount)
            return
          }
        }
        if (compensateType == 2) {
          if (amount > detail.realTemplateFreight && this.title == '通过') {
            this.warnInfo(amount, detail.realTemplateFreight)
            return
          }
        }
        if (compensateType == 3) {
          if (amount > detail.maxAmount && this.title == '通过') {
            this.warnInfo(amount, detail.maxAmount)
            return
          }
        }
      } else {
        if (amount > detail.maxAmount && this.title == '通过') {
          this.warnInfo(amount, detail.maxAmount)
          return
        }
      }
      if (type == 3) {
        //判断退款的钱与支付订单的钱的大小，若退款钱比较大则提示
        //console.log(amount, detail.realAmount)
        if (amount > detail.realAmount && this.title == '通过') {
          this.$confirm(`审核通过后将退回到${label}里！`, '提示', {
            type: 'warning'
          }).then(async () => {
            if (this.name == 'admin') {
              // 超管直接提交
              this.$emit('submit', null, this.initValue.handleStatus)
              return
            }

            let valid = await awaitFormResolve(this.openValidate())
            if (!valid) return
            this.dialogVisible = true
          })
          return
        }

        if (this.title == '拒绝') {
          this.$confirm(`确认是否拒绝！`, '提示', {
            type: 'warning'
          }).then(async () => {
            let valid = await awaitFormResolve(this.openValidate())
            if (!valid) return
            this.dialogVisible = true
          })
          return
        }

        try {
          const { code, detail } = await passwordSetted({})
          if (!$SUC(code)) {
            // 如果当前登录用户没有设置交易密码，则提示跳转并return
            if (detail == 0 && !this.is_super) {
              this.$message.warning('您还未设置交易密码，请先设置后再审核！')
              return
            }
          }
        } catch (error) {}
      }
      let valid = await awaitFormResolve(this.openValidate())
      if (!valid) return

      if (this.is_super && type == 3) {
        this.dialogVisible = false
        this.$emit('submit', '', this.initValue.handleStatus)
      } else {
        this.dialogVisible = true
      }
    },
    warnInfo(a, b) {
      this.$message.warning(`退款金额：${a},不能大于最大退款金额：${b}`)
    },
    submit() {
      let val = this.textarea
      if (this.initValue.handleStatus != HANDLE_STATUS_SUCCESS_BY_FINANCE) {
        let isnull = false
        if (val.match(/^\s*$/)) {
          //空|空格|换行
          isnull = true
        }
        if (isnull) {
          return this.$message({
            type: 'worn',
            message: `${this.info}不能为空`
          })
        }
      } else {
        let value = this.$refs.password.passwordVal

        if (value.length !== 6) {
          return this.$message({
            type: 'worn',
            message: '请输入六位交易密码！'
          })
        }
        val = value.join('')
      }

      this.dialogVisible = false
      this.$emit('submit', val, this.initValue.handleStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
